import React from 'react';
import {
  Dialog, DialogTitle, DialogContent, DialogActions, Button, Link,
} from '@mui/material';

const TermsAndConditionsDialog = ({ open, onClose, onAccept }) => (
  <Dialog open={open} onClose={onClose}>
    <DialogTitle>Terms and Conditions</DialogTitle>
    <DialogContent>
      {/* Include your terms and conditions text here */}
      <p>
        By clicking on accept, you agree to our
        {' '}
        <Link href="/settings/termsconditions">terms</Link>
        {' '}
        and
        {' '}
        <Link href="/settings/termsconditions">conditions</Link>
        {' '}
        and
        {' '}
        <Link href="/settings/termsconditions">privacy policy</Link>
        .
      </p>
    </DialogContent>
    <DialogActions>
      <Button onClick={onClose}>Cancel</Button>
      <Button onClick={onAccept} variant="contained" color="primary">Accept</Button>
    </DialogActions>
  </Dialog>
);

export default TermsAndConditionsDialog;
