import { makeStyles } from '@mui/styles';

export default makeStyles((theme) => ({
  container: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
  containerMap: {
    flexBasis: '40%',
    flexShrink: 0,
  },
  containerMain: {
    overflow: 'auto',
  },
  header: {
    position: 'sticky',
    left: 0,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'stretch',
  },
  columnAction: {
    width: '1%',
    paddingLeft: theme.spacing(1),
  },
  filter: {
    display: 'inline-flex',
    flexWrap: 'wrap',
    gap: theme.spacing(2),
    padding: theme.spacing(3, 2, 2),
  },
  filterItem: {
    minWidth: 0,
    flex: `1 1 ${theme.dimensions.filterFormWidth}`,
  },
  filterButtons: {
    display: 'flex',
    gap: theme.spacing(1),
    flex: `1 1 ${theme.dimensions.filterFormWidth}`,
  },
  filterButton: {
    flexGrow: 1,
  },
  chart: {
    flexGrow: 1,
    overflow: 'hidden',
  },
  cardContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'flex-start',
  },
  card: {
    margin: theme.spacing(1),
    width: 'calc(100% / 3 - 16px)',
    [theme.breakpoints.down('lg')]: {
      width: 'calc(100% / 2 - 16px)',
    },
    [theme.breakpoints.down('sm')]: {
      width: 'calc(100% - 16px)',
    },
  },
  cardHeader: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white,
    padding: theme.spacing(1),
  },
  cardContent: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    fontWeight: '300',
  },
  cardRow: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: theme.spacing(1),
  },
  cardLabel: {
    marginRight: theme.spacing(1),
    fontWeight: '300',
  },
  cardValue: {
    fontWeight: '300',
  },
  buttonWrapper: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white,
    marginLeft: theme.spacing(1),
    marginTop: theme.spacing(1),
    borderRadius: theme.shape.borderRadius,
  },
  alertButton: {
    display: 'flex',
    color: theme.palette.common.white,
  },
  cardTripWrapper: {
    display: 'flex',
    flexDirection: 'row',
    height: '100%',
  },
  cardTripHeading: {
    fontWeight: '500',
  },
  cardDetailsWrapper: {
    paddingLeft: '20px',
    paddingRight: theme.spacing(1),
  },
  cardTripValue: {
    fontWeight: '300',
    fontSize: '0.8rem',
    display: 'flex',
    alignItems: 'center',
  },
  cardSmallIcoon: {
    display: 'inline-flex',
    alignItems: 'center',
    justifyContent: 'center',
    paddingRight: '5px',
  },
  lineSeparator: {
    width: '4px',
    marginTop: '18px',
    marginLeft: '10px',
    marginRight: '10px',
    borderLeft: '4px dotted #12b4c6',
  },
  cardTripFooter: {
    display: 'flex',
    flexDirection: 'row',
    marginTop: '10px',
    borderTop: '1px solid rgba(0, 0, 0, 0.12)',
  },
  cardFooterElement: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    fontSize: '0.7rem',
    fontWeight: '300',

  },
  cardTripFooterElement: {
    marginTop: '20px',
    width: '25%',
    borderRight: '1px solid rgba(0, 0, 0, 0.12)',
    paddingLeft: '10px',
    paddingRight: '10px',
    '&:last-child': {
      borderRight: 'none',
    },
  },
  buttonAlertWrapper: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white,
    display: 'inline-flex',
    alignItems: 'center',
    justifyContent: 'center',
    margin: '10px',
  },
  cardSummaryContainer: {
    width: 'calc(100% / 2 - 16px)',
    margin: '0 auto',
    backgroundColor: 'white',
    [theme.breakpoints.down('sm')]: {
      width: 'calc(100% - 16px)',
    },
  },
}));
