import React from 'react';
import { Card, CardContent, Skeleton } from '@mui/material';

const TableShimmer = ({ columns, startAction, endAction }) => (
  <Card style={{ width: '100%' }}>
    {[...Array(3)].map((_, i) => (
      <CardContent key={-i}>
        {[...Array(columns)].map((_, j) => {
          const action = (startAction && j === 0) || (endAction && j === columns - 1);
          return (
            <div key={-j} style={{ padding: '8px' }}>
              {!action && <Skeleton />}
            </div>
          );
        })}
      </CardContent>
    ))}
  </Card>
);

export default TableShimmer;
