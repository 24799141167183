import React from 'react';
import {
  Divider, List, ListItemButton, ListItemIcon, ListItemText, Typography,
} from '@mui/material';
import SettingsIcon from '@mui/icons-material/Settings';
import CreateIcon from '@mui/icons-material/Create';
import DeleteIcon from '@mui/icons-material/ArrowForwardIos';
import NotificationsIcon from '@mui/icons-material/Notifications';
import PersonIcon from '@mui/icons-material/Person';
import StorageIcon from '@mui/icons-material/Storage';
import PeopleIcon from '@mui/icons-material/People';
import PublishIcon from '@mui/icons-material/Publish';
import SmartphoneIcon from '@mui/icons-material/Smartphone';
import { Link, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useTranslation } from '../../common/components/LocalizationProvider';
import {
  useAdministrator, useDeviceReadonly, useManager, useRestriction,
} from '../../common/util/permissions';
import useFeatures from '../../common/util/useFeatures';

const MenuItem = ({
  title, link, icon, selected,
}) => (
  <ListItemButton key={link} component={Link} to={link} selected={selected}>
    <ListItemIcon style={{ minWidth: '35px' }}>{icon}</ListItemIcon>
    <ListItemText primary={title} />

    <DeleteIcon style={{ fontSize: '12px' }} edge="end" />

  </ListItemButton>
);

const SettingsMenu = () => {
  const t = useTranslation();
  const location = useLocation();

  const readonly = useRestriction('readonly');
  const deviceReadonly = useDeviceReadonly();
  const admin = useAdministrator();
  const manager = useManager();
  const userId = useSelector((state) => state.session.user.id);

  const features = useFeatures();

  return (
    <>
      <List>
        <Typography variant="h6" color="inherit" style={{ fontSize: '15px', paddingLeft: '20px', paddingRight: '20px', paddingTop: '20px', paddingBottom: '10px', backgroundColor: '#F5F5F5' }}>
          Account Settings
        </Typography>
        <MenuItem
          title={t('sharedPreferences')}
          link="/settings/preferences"
          icon={<SettingsIcon />}
          selected={location.pathname === '/settings/preferences'}
        />
        {!readonly && (
          <MenuItem
            title={t('settingsUser')}
            link={`/settings/user/${userId}`}
            icon={<PersonIcon />}
            selected={location.pathname === `/settings/user/${userId}`}
          />
        )}
        <Typography variant="h6" color="inherit" style={{ fontSize: '15px', paddingLeft: '20px', paddingRight: '20px', paddingTop: '20px', paddingBottom: '10px', backgroundColor: '#F5F5F5' }}>
          Device Settings
        </Typography>

        {!readonly && (
          <>
            <MenuItem
              title={t('sharedNotifications')}
              link="/settings/notifications"
              icon={<NotificationsIcon />}
              selected={location.pathname.startsWith('/settings/notification')}
            />

            {!deviceReadonly && (
              <MenuItem
                title={t('deviceTitle')}
                link="/settings/devices"
                icon={<SmartphoneIcon />}
                selected={location.pathname.startsWith('/settings/device')}
              />
            )}
            <MenuItem
              title={t('sharedGeofences')}
              link="/geofences"
              icon={<CreateIcon />}
              selected={location.pathname.startsWith('/settings/geofence')}
            />

            {!features.disableComputedAttributes && (
              <MenuItem
                title={t('sharedComputedAttributes')}
                link="/settings/attributes"
                icon={<StorageIcon />}
                selected={location.pathname.startsWith('/settings/attribute')}
              />
            )}

            <MenuItem
              title={t('sharedSavedCommands')}
              link="/settings/commands"
              icon={<PublishIcon />}
              selected={location.pathname.startsWith('/settings/command') && !location.pathname.startsWith('/settings/command-send')}
            />
          </>
        )}

        <Typography variant="h6" color="inherit" style={{ fontSize: '15px', paddingLeft: '20px', paddingRight: '20px', paddingTop: '20px', paddingBottom: '10px', backgroundColor: '#F5F5F5' }}>
          Support & About
        </Typography>
        <MenuItem
          title="Terms & Conditions"
          link="/settings/termsconditions"
          icon={<SettingsIcon />}
          selected={location.pathname === '/settings/termsconditions'}
        />
      </List>
      {manager && (
        <>
          <Divider />
          <List>
            {admin && (
              <MenuItem
                title={t('settingsServer')}
                link="/settings/server"
                icon={<StorageIcon />}
                selected={location.pathname === '/settings/server'}
              />
            )}
            <MenuItem
              title={t('settingsUsers')}
              link="/settings/users"
              icon={<PeopleIcon />}
              selected={location.pathname.startsWith('/settings/user') && location.pathname !== `/settings/user/${userId}`}
            />
          </List>
        </>
      )}
    </>
  );
};

export default SettingsMenu;
