import React from 'react';

import PageLayout from '../common/components/PageLayout';
import SettingsMenu from './components/SettingsMenu';

const NotificationsPage = () => (
  <PageLayout menu={<SettingsMenu />} breadcrumbs={['settingsTitle', 'TermsConditionsTitle']}>

    <div style={{ padding: '20px', backgroundColor: 'white', margin: '20px' }}>
      <h1 style={{ fontSize: '24px', color: '#12b4c6' }}>DAMIA SECURITY SOLUTIONS - CONSUMER TERMS AND CONDITIONS</h1>
      <p>This is your agreement with Damia Security Solutions for the services we offer</p>
      <p>The agreement is made up in Four parts:</p>
      <ol style={{ paddingLeft: '20px' }}>
        <li>The application.</li>
        <li>The terms and conditions.</li>
        <li>
          The terms of use for the Tracker website and Tracker mobile
          applications (if you use the website or mobile application)

        </li>
        <li>The Tracker privacy policy</li>
      </ol>
      <p>The agreement is laid out by the following:</p>
      <ul style={{ paddingLeft: '20px' }}>
        <li>Section A: The agreement.</li>
        <li>Section B: The Damia Solutions tracking units</li>
        <li>Section C: International Borders and Roaming</li>
        <li>Section D Service interruptions and Delays</li>
        <li>Section E: Fee and Charges</li>
        <li>Section F: Personal information and Data usage</li>
        <li>Section G: Intellectual Property Rights</li>
        <li>Section H: Changes to the agreement</li>
        <li>Section I: Ending the agreement</li>
        <li>Section J: Our legal responsibility to you is limited</li>
        <li>Section K: Indemnity</li>
        <li>Section L: The right to sub-contract services and administration</li>
        <li>Section M: Transfer of rights and legal responsibilities</li>
        <li>Section N: Neither you nor we give up rights</li>
        <li>Section O: Each provision is separate</li>
        <li>Section P: The Laws of The Gambia applies</li>
        <li>Section Q: Guidelines to interpreting the agreement</li>
        <li>Section R: The Service you subscribe to</li>
      </ul>
      <h2 style={{ fontSize: '20px', color: '#12b4c6' }}>A. The Agreement</h2>
      <ol style={{ paddingLeft: '20px' }}>
        <li>
          {' '}
          <b>The Agreement is between (Parties)</b>
          {' '}
          <br />
          The parties to the agreement are:
          <ul style={{ paddingLeft: '20px', listStyle: 'lower-alpha' }}>
            <li>Damia Security Solutions, referred to as ‛we’, ‛us’ and ‛our’ in this document;</li>
            <li>The customer and partners named on the application, referred to as ‛you’ and ‛you’re’ in this document.</li>
          </ul>
        </li>
        <li>
          <b>When The agreement starts</b>
          {' '}
          <br />
          The agreement starts when we record the unit installed in the vehicle against your name on the Damia Security Solution
          system, or a third party on your behalf. This may take up to 48 (forty-eight) hours. Until we record the unit on the Damia
          Security Solution system against your name, we will not be able to provide the services or part thereof. If a unit is not yet
          installed in your vehicle at the time that you buy it, you must not allow the unit to be installed in the vehicle if you do not
          agree to the agreement.
        </li>
      </ol>
      <h2 style={{ fontSize: '20px', color: '#12b4c6' }}>B. The Damia Solutions tracking units</h2>
      <div>
        <p>
          The unit is the positioning and/or communications equipment that we use to provide the services. If the unit is a Damia
          Security Solutions unit, the following will apply:
        </p>
        <h3>1 Unit Installation</h3>
        <p>We offer two options for your device installation:</p>
        <ul style={{ paddingLeft: '20px', listStyle: 'lower-alpha' }}>
          <li>Bring your vehicle to one of our installation locations</li>
          <li>
            If you arrange with us for a technician to install the unit at an address you chose: There might be a call-out fee.
            Please talk to us before making any arrangement.

          </li>
          <li>
            If we have agreed to a time and place to instal the unit, please make sure the vehicle is there on time, if not This
            will cost delay in installation, since you may have to wait on the queue.
          </li>
          <li>
            the place for the installation must be safe and be out of the view of third parties. Our technician has the right to
            refuse to install the unit if they believe the place is not safe and secure.
          </li>
        </ul>
        <h3>2 Warranty</h3>
        <p>
          The Damia Security Solutions Tracking Devices come with a 12-month warranty that begins from the day of
          installation. We will repair the unit in case it doesn&#39t work properly due to any defect while in the warranty period
          without any cost. However, the warranty doesn&#39t apply under the following circumstances:

        </p>
        <ul style={{ paddingLeft: '20px', listStyle: 'lower-alpha' }}>
          <li>Any tampering with the unit or the way it was installed.</li>
          <li>
            Unauthorized personnel repaired or changed the installation of the unit.
          </li>
          <li>
            The unit caused a problem due to water damage.
          </li>
          <li>
            The unit caused a problem due to abuse.
          </li>
          <li>The unit caused a problem due to a collision or accident.</li>
          <li>The defect in the unit was not caused by the manufacturer, importer, distributor, retailer, or us.</li>
          <li>If the defect was reported after the warranty expired.</li>
        </ul>
      </div>
      <h3>3 If the unit or its installation affects the operation of or damages the vehicle,</h3>
      <div>
        <p>we will fix the problems at our own cost if all of the following conditions are met:</p>
        <ul style={{ paddingLeft: '20px', listStyle: 'lower-alpha' }}>
          <li>
            The unit or its installation damaged or caused a problem to your vehicle and how it works. We have the right to
            appoint an expert at our cost for the problem investigation
          </li>
          <li>
            The unit or its installation wasn&#39t tampered with or repaired by unauthorized personnel
          </li>
          <li>
            The unit wasn&#39t abused.
          </li>
          <li>
            The unit wasn&#39t subjected to water damage.
          </li>
          <li>The unit wasn&#39t subjected to an accident or collision.</li>
        </ul>
        <p>
          It is your duty to make the vehicle available for inspection at the designated time if you report a problem to us, and we
          send a technician. You will have to pay the no-show fee if you fail to do so. If our technician inspects and finds out that
          the problem wasn&lsquo;t due to the unit or its installation, you will be legally responsible to pay the technician&lsquo;s fees, including
          call-out fees and other costs. You can inquire from us about the costs and fees when you call to report any faults. If we
          damage your vehicle during the installation, we have the right to inspect and fix the damage through a Damia Security
          Solutions-appointed supplier. If you choose a different supplier, you will be liable for any costs over and above what the
          Damia Security Solutions appointed supplier quoted.
        </p>
      </div>
      <div>
        <h2 style={{ fontSize: '20px', color: '#12b4c6' }}>C International Borders and Roaming</h2>
        <ul style={{ paddingLeft: '20px', listStyle: 'decimal' }}>
          <li>
            We provide our services only in the Gambia. Outside of Gambia, we can only identify the general location of the
            vehicle if there is GPS, GSM network coverage, or radio frequency network coverage. If you have The Damia Security
            Solutions trackers installed on your car and want it to travel out of the bounds of Gambia, contact us before activating
            international roaming for extra charges. However, you must know:

          </li>
          <ul style={{ paddingLeft: '20px', listStyle: 'lower-alpha' }}>
            <li>We will not be able to track your vehicle to the exact location or recover it outside the Gambia,</li>
            <li>
              We will not be legally responsible for any damages; you must use the vehicle out of The Gambia at your own
              risk
            </li>
          </ul>
        </ul>
        <p>
          You are legally responsible for the international roaming and telecommunications charges that we incur in providing the
          services to you. Some of these international roaming and telecommunication charges may be incurred while the vehicle is
          in The Gambia but near a border with another country
        </p>
      </div>
      <div>
        <h2 style={{ fontSize: '20px', color: '#12b4c6' }}>D Service interruptions and Delays</h2>
        <p>
          We will do our best to maintain the availability of the services to you, however, the services might be delayed or
          interrupted. We will try to get our service back on track ASAP, but they are inevitable under the following circumstances:

        </p>

        <ul style={{ paddingLeft: '20px', listStyle: 'lower-alpha' }}>
          <li>A technical failure (unavailability of radio or network communication network)</li>
          <li>
            The network service provider stops operating
          </li>
          <li>
            If the unit is faulty and we have informed you via SMS, but you don’t bring the vehicle to us for
            repairs/replacement of unit.

          </li>
          <li>If the unit isn’t from Damia Security Solutions</li>
          <li>We are required by a government authority to stop the services</li>
          <li>Strikes or other industrial actions have stopped the services.</li>
          <li>If there are other circumstances beyond our control, for example fire and flood;</li>
          <li>If there are strikes or other industrial action.</li>
        </ul>

        <p>
          In the above scenarios, we don’t have any legal responsibility; you accept it and will not ask for a fee refund under them.
        </p>
        <h3>Disclaimer</h3>
        <p>
          If we cannot restore availability, we will do our best to restore any interruption to the services and to shorten any delay. If
          there is an interruption or delay to the services because of the circumstances referred to above (except for D) and E), and
          we cannot restore availability within 30 days, we will notify you in writing. You then have the right to end the agreement

        </p>
        <p>
          It is your responsibility to understand how the unit works, what services you or a third party on your behalf pay for and to
          ensure that the unit is working It is your responsibility to understand what features are included in the service.

        </p>
      </div>

      <div>
        <h2 style={{ fontSize: '20px', color: '#12b4c6' }}>E. Fee and Charges</h2>
        <p>
          <b>1 Six- and twelve-months Full Payment</b>
          {' '}
          <br />
          We have a full advance payment method that includes paying the full amount for the whole 6 months,12 months. You can
          pay in cash with a bank deposit or using money transfer services. Proof of payment will be handed out for the entire year
          through the website or mobile app and by extension to our email. We can also hand out physical copy on request
        </p>
        <p><b>2 Instalment</b></p>
        <p>Customers with 12-month payment plan can pay in instalments with zero interest for with the following conditions;</p>
        <ul style={{ paddingLeft: '20px', listStyle: 'lower-alpha' }}>
          <li>Valid driving licence</li>
          <li>
            Proof of vehicle ownership
          </li>
          <li>
            A valid Gambian ID card or passport
          </li>
          <li>Copy of Business registration *Business owners*</li>
        </ul>

        <p>
          You can choose to receive your invoices by email, or you can get a copy of your invoice by logging on to the Damia
          Security Solutions website or Mobile app.
        </p>
      </div>
      <div>
        <h2 style={{ fontSize: '20px', color: '#12b4c6' }}>F. Personal information and Data usage</h2>
        <p>
          The Damia Security Solutions privacy policy is published on the Damia security solutions’ website. It governs the way
          we share your personal information, usage data, driver behaviour information, anonymised data and any other data we get
          from the unit installed in your vehicle. (Explanations of these terms can be found in the Tracker privacy policy.)
        </p>
        <p>The Tracker privacy policy forms a part of the agreement.</p>
        <p>
          You warrant that the information that you give to us is true. You accept that we have the right to treat the statements you
          make as true. This means that you cannot later claim that the statements you made are not true.

        </p>
        <h3>1.1 You warrant that you accept the Tracker privacy policy</h3>
        <p>You warrant that you have read, understood and agree to the Damia Security Solution’s privacy policy.</p>
        <p>
          You accept that by agreeing to the Damia Security Solution’s privacy policy you are giving up some of your rights to
          privacy and giving us the right to use your personal information according to the Damia Security Solution’s privacy policy.
          You accept that you do not have the right to take action against us for any loss or damages you suffer from us processing
          your personal information according to the Damia Security Solutions privacy policy.
        </p>
        <h3>1.2 You warrant that you can give us personal information You warrant that:</h3>
        <p>a) you are properly authorised and allowed to give us personal information;</p>
        <p>
          b) you will tell us in writing or by phone when there is any change or update to any of your personal information
          (including medical aid information, if applicable) or, if you are representing someone else in the agreement, the
          personal information of the person who you represent. By giving this warranty, you accept that we may have claims
          against you for loss or damages that we suffer if you do not have authority to give us personal information and a
          third party brings a claim against us because we used the personal information you gave us.
        </p>
        <h3>1.3 You warrant that information you give is true and correct</h3>
        <p>
          You warrant that all information, including personal information that you or somebody representing you gives to us
          is true and correct.

        </p>
        <h3>1.4 You warrant that you have consent for us to process information</h3>
        <p>
          If you enter the agreement on behalf of another person or juristic (legal) entity, you warrant that you have received
          the relevant consent for us to process personal information, usage data, driver behaviour information, anonymised
          data and any other data we get from the unit installed in your vehicle according to the Damia Security Solutions
          privacy policy. This includes the owner and driver of the vehicle. Examples of a juristic entity include a company, a
          trust and a partnership

        </p>
        <h3>1.5 You warrant that you have informed those with the right to know</h3>
        <p>
          You warrant that you have informed all people or entities who have the right to be informed (including the owner
          and driver or drivers of the vehicle) about:
        </p>
        <ul style={{ paddingLeft: '20px', listStyle: 'lower-alpha' }}>
          <li>the installation of the unit in the vehicle;</li>
          <li>your agreement with us for the services;</li>
          <li>
            your confirmation that we may process your personal information, usage data, driver behaviour information and
            anonymised data according to the agreement.

          </li>
        </ul>
        <p>
          You accept that if any of the statements above are not true, we have claims against you for loss or damages we might
          suffer because we relied on your warranties. Our claims could include amounts and damages that we must pay to other
          people or any regulatory authority because these statements are, in fact, not true. For example, if the owner or driver of
          the vehicle claims against us for loss or damages because they did not give permission for a unit to be installed in the
          vehicle where their permission was required, you will have to pay us the amounts of these claims
        </p>

      </div>

      <div>
        <h2 style={{ fontSize: '20px', color: '#12b4c6' }}>G. Intellectual Property Rights</h2>
        <p>
          The intellectual property includes everything relevant to the Tracker unit, our services, the Damia Security Solutions
          website, our mobile application, or given by us in any form whatsoever. It also includes:
        </p>

        <ul style={{ paddingLeft: '20px', listStyle: 'lower-alpha' }}>
          <li>
            All products and goods, including any materials that, might be placed in the Damia Security Solutions unit or be
            used together with the Tracker unit. For Tracker telematics units, this includes the SIM card that comes with the
            unit and information that is or can be derived from the SIM card

          </li>
          <li>
            Databases, information, compilation, and usage of data as defined by our privacy policy.
          </li>
          <li>
            Software and its documentation, hardware and firmware, API interfaces, servers, computer tools, codes, and
            other platforms.
          </li>
          <li>Algorithms, designs, and specifications;</li>
          <li>Logos, trademarks, and names;</li>
          <li>Links, graphics, and images;</li>
          <li>Notes, reports, files, text, plans, manuals, records, templates, and any other published stuff.</li>
          <li>Music, sounds, audios;</li>
          <li>Authored or derivative works;</li>
          <li>Protocols, routines, methods, procedures, policies, and techniques;</li>
          <li>Modules and components;</li>
          <li>Adjustments to any of these.</li>
        </ul>

        <p>
          We have the right to use all of these. This includes intellectual property that we have licensed from a third party, that we
          license, or that we give the right of use to our suppliers so that they are able to provide the services. For instance, the SIM
          card that is used in the Tracker unit, where applicable. You do not have or acquire any intellectual property rights during
          the agreement or after it ends except for those intellectual property rights, we expressly give to you in the agreement. We
          are entitled to modify intellectual property without sending you a notice
        </p>
      </div>

      <div>
        <h2 style={{ fontSize: '20px', color: '#12b4c6' }}>H. Changes to the agreement</h2>
        <p><b>1 We have the right to make changes to the agreement</b></p>
        <p>
          We have the right to make changes to the agreement from time to time. We will tell you about the changes at least 30
          calendar days before the changes come into effect if such changes impact on your rights. We have the right to inform you
          of changes in a shorter period in any of the following circumstances:

        </p>
        <ul style={{ paddingLeft: '20px', listStyle: 'lower-alpha' }}>
          <li>If the change is required by law;</li>
          <li>If it is reasonable to give less than 30 calendar days&lsquo; notice, having regard to the nature or content of the change</li>
        </ul>
      </div>

      <div>
        <h2 style={{ fontSize: '20px', color: '#12b4c6' }}>I. Ending the agreement</h2>
        <p>
          You have the right to end the agreement by giving us one calendar month notice. A calendar month is from the first day
          of a month to the last day of a month. This means we must receive your notice on or before the first day of the month to
          end the agreement at the end of that month.

        </p>
        <ul style={{ paddingLeft: '20px', listStyle: 'lower-alpha' }}>
          <li>
            Either party may terminate this Agreement at any time upon written notice to the other party. The Client agrees
            to provide a notice of termination at least two weeks prior to the termination date.
            {' '}

          </li>
          <li>
            This Agreement shall automatically terminate upon the expiration of the subscription period specified in the
            Statement of Work, unless the parties agree to renew the subscription for an additional period.

          </li>
          <li>
            {' '}
            In the event that the Client fails to adhere to any of the rules or requirements set forth in this Agreement or the
            Statement of Work, the Company may terminate this Agreement upon written notice to the Client and have the
            tracker remove from the vehicle
            {' '}

          </li>
        </ul>
        <p>
          If the customer decides to terminate the contract prior to the end of the subscription period, there will be no refund of fees
          paid for the remainder of the subscription period. All tracking devices (the &ldquo;Trackers&ldquo;) that have been provided to the
          customer must be returned to us in good condition.

        </p>
        <p><b>Damaged Trackers:</b></p>
        <p>
          If any of the Trackers are damaged due to the customer&lsquo;s fault or negligence, the customer will be responsible for paying
          a fee to cover the cost of the damage. This fee will be determined based on the extent of the damage caused.

        </p>
        <p><b>Tracker Removal:</b></p>
        <p>
          The customer can choose to have us remove the Trackers for them for a fee or they may remove the Trackers themselves.
          If the customer chooses to remove the Trackers themselves, they must do so with care and follow the instructions provided
          to them. If the customer causes any damage to the Trackers, or to the vehicle, during the removal process, the customer
          will be responsible for any resulting costs or damages.

        </p>
      </div>
      <div>
        <h3>4. If the vehicle is sold, stolen/hijacked, written off or repossessed</h3>
        <p>
          The agreement does not end automatically if you sell the vehicle or if there is theft, loss or damage to the unit or the
          vehicle that it is fitted to. If the vehicle is stolen/hijacked, written off or repossessed, you must tell us immediately. If you
          sell the vehicle, you must tell us at least three days before you hand the vehicle over to the new owner.

        </p>
        <p>
          Until the agreement is cancelled in the way allowed in this section, you accept that you remain legally responsible for all
          fees for the remainder of the agreement.
          {' '}

        </p>
        <h4>4.1 If you sell the vehicle</h4>
        <p>
          If you sell the vehicle before the end of the agreement, you can transfer the agreement to your next vehicle if you have a
          Damia tracker unit installed. If you choose to do this, we will need certain information from you (for example, details of
          your next vehicle and the date of purchase). We may remove the Damia tracker unit from the vehicle and install it into
          your next vehicle or we may install a new Damia tracker unit in the new vehicle. There is a charge for removing the Damia
          tracker unit and/or installing a new Damia tracker unit in your next vehicle. Before you ask to transfer the agreement,
          please ask us what charge applies at the time.

        </p>
        <h4>
          4.2 If you sell the vehicle before the end of the agreement.
        </h4>
        <p>
          If you sell the vehicle before the end of the agreement and opt not to transfer the agreement to your next vehicle,
          you can choose to end the agreement instead. Please note, this decision is non-refundable. Once this decision is made,
          we can arrange for the removal of the Damia tracker unit from your vehicle. Removal and termination fees may apply in this situation.
          We highly recommend that you consult with us about the applicable charges before choosing to terminate the agreement.

        </p>
        <h4>4.3 If the vehicle is stolen or hijacked</h4>
        <p>
          If the vehicle is stolen or hijacked and recovered (even if not by us), the agreement will continue. If the vehicle is stolen
          or hijacked and not recovered, the agreement will end at the end of the month after the month in which the vehicle was
          stolen or hijacked, and early cancellation costs will not apply. We wil however need proof.

        </p>
        <h4>4.4 If the vehicle is repossessed</h4>
        <p>
          If you can no longer afford the finance agreement on the vehicle and it is repossessed by the financing company, you can
          cancel the agreement with no refund. If you end the agreement because the vehicle is sold, stolen or hijacked and recovered,
          written off or repossessed, you accept that hen the agreement will be cancel with no-refund
        </p>
        <h3>5 If you breach the agreement</h3>
        <h4>5.1 When we can end the agreement immediately</h4>
        <p>We have the right to end the agreement immediately with no refund if you do any one or more of the following:</p>
        <ul style={{ paddingLeft: '20px', listStyle: 'lower-alpha' }}>
          <li>
            You do not have the consent of the owner of the vehicle to enter into the agreement and/or to use the services
            for the vehicle and/or sell the vehicle without informing Damia Security Solutions;

          </li>
          <li>You do not get consent or make the required disclosures to the owner or driver of the vehicle, as required;</li>
          <li>You use the services in any way or for any purpose that is invalid or illegal;</li>
          <li>
            You report the vehicle as stolen or hijacked knowing that it was not stolen or hijacked, or should reasonably
            have known that it was not stolen or hijacked. If we are entitled to end the agreement immediately, we do not
            need to give you time to comply and correct your breach.

          </li>
        </ul>
        <h4>5.2 When we will give you time to correct your breach</h4>
        <p>
          If you breach any term of the agreement or break any of your warranties for reasons other than those given
          above, you must correct your breach within 14 business days after we have notified you of the breach.

        </p>
        <p>If we end the agreement immediately or you do not correct your breach within 14 business days, you accept that we have the right to do any one or more of the following:</p>
        <ul style={{ paddingLeft: '20px', listStyle: 'lower-alpha' }}>
          <li>Suspend the services;</li>
          <li>End the agreement;</li>
          <li>
            Claim from you any amounts you still owe to us, including the fees that you would have had to pay if the
            agreement had continued until its original end date. You must pay these amounts immediately on demand and
            return the tracker in a good condition

          </li>
        </ul>
        <h4>5.3 Other rights if you breach the agreement</h4>
        <p>
          These rights do not affect any other rights we might have in the agreement or in law. If the breach by you is not a
          material breach, we will only exercise our rights in sections I5.1 and I5.2 where it is reasonable for us to do so.

        </p>
        <h4>5.4 Your legal responsibility if you breach the agreement</h4>
        <p>If you breach the agreement, you agree:</p>
        <ul style={{ paddingLeft: '20px', listStyle: 'lower-alpha' }}>
          <li>
            to pay us and our suppliers the value of all loss or damages that we or suppliers sufferas a result of you breaching
            the agreement;

          </li>
          <li>
            to pay us and our and suppliers the value of all loss or damages we suffer from claims that are brought against
            us or suppliers as a result of you breaching the agreement;

          </li>
          <li>
            to pay all legal costs reasonably incurred by us on the scale as between attorney-and-own-client if we have to
            take legal steps against you;
            {' '}

          </li>
          <li>to pay collection costs reasonably incurred by us while trying to collect any amounts that you owe to us.</li>
        </ul>
        <p>
          If you breach the agreement (which includes breaking your warranties or undertakings), you accept that you are required
          to take on the legal responsibility for loss and damages that we or suppliers might suffer or be required to pay. You accept
          that this might also lead to us having claims against you and to you being legally responsible to us for extra amounts,
          including any costs or damages we must pay to our affiliates or suppliers. You accept that you are also legally responsible
          to pay:
          <br />
          a) any legal costs that we pay to our lawyers. These costs are calculated at a much higher rate than the courts
          normally apply;
          <br />
          b) any amounts that we pay in trying to collect or get you to pay money that you owe to us.

        </p>
        <h3>6 If we breach the agreement</h3>
        <p>
          If we breach any term of the agreement, you must give us 14 business days to correct the breach. If we do not correct the
          breach within that time, you have the right to cancel the agreement. You must give us 20 business days’ notice after the
          14-day period ends. If we breach the agreement, we will pay the following when you ask us to:

        </p>
        <ul style={{ paddingLeft: '20px', listStyle: 'lower-alpha' }}>
          <li>Legal costs on an attorney-and-client scale related to our breach;</li>
          <li>Other collection charges and commissions incurred by you related to our breach</li>
        </ul>
      </div>
      <div>
        <h2 style={{ fontSize: '20px', color: '#12b4c6' }}>J Notices by SMS, email or Damia website and mobile app</h2>
        <p>We have the right to send you notices about the following issues by SMS or email or on the Damia app or website:</p>
        <ul style={{ paddingLeft: '20px', listStyle: 'lower-alpha' }}>
          <li>Confirmation of your entering into this agreement;</li>
          <li>Increases to fees;</li>
          <li>Defective units or units that are not reporting;</li>
          <li>Not receiving your payments in time or at all;</li>
          <li>Notices that we intend to suspend the services to you</li>
        </ul>
        <p>
          You choose your preferred contact method (SMS or email or both) in your application. We will use our best efforts to
          send all communications and notices to you by your preferred contact method, but may communicate with you through
          another method

        </p>

      </div>
      <div>
        <h2 style={{ fontSize: '20px', color: '#12b4c6' }}>K Our legal responsibility to you is limited</h2>
        <p>As far as the law allows, we are not legally responsible for:</p>
        <ul style={{ paddingLeft: '20px', listStyle: 'lower-alpha' }}>
          <li>
            any loss or damages that you might suffer where there is any delay, suspension or interruption in the services
            because of any of the events or circumstances referred to in section D;

          </li>
          <li>
            any loss or damages that you might suffer because of our, our suppliers’ or our’ negligence. This excludes gross
            negligence or wilful misconduct. Gross negligence is a serious lack of care in performing a legal duty owed to
            you under the agreement. Wilful misconduct is deliberately doing something that should not be done or
            deliberately not doing something that should be done, knowing that someone might suffer loss or damages as a
            result;

          </li>
          <li>
            any delay, breakdown, failure or loss that you might suffer because of a defect or deficiency in the intellectual
            property;
            {' '}

          </li>
        </ul>
        <p>any failure, delay or interruption in your use of the Tracker service or the Tracker website, including:</p>
        <ul style={{ paddingLeft: '20px', listStyle: 'decimal' }}>
          <li>system and server crashes;</li>
          <li>system errors;</li>
          <li>computer malfunctions;</li>
          <li>unit or software faults;</li>
          <li>security breaches;</li>
          <li>theft;</li>
          <li>incompatibility issues;</li>
          <li>fire, flood, any other event referred to as an &ldquo;Act of God&ldquo;;</li>
          <li>war, civil disturbances;</li>
          <li>international restrictions, embargoes;</li>
          <li>any lost, corrupted or undelivered data or information, regardless of the cause;</li>
          <li>any loss of profits, business or revenue that you suffer;</li>
          <li>any indirect loss or indirect damages that you suffer.</li>
        </ul>
        <p>
          You accept that there are certain types of loss or damages that you may suffer that you cannot claim from us at all. This
          includes the loss and damages listed in this clause above, and loss or damages that result from any of the events listed in
          this clause above. You use the services and the unit knowing and accepting these risks.

        </p>
      </div>
      <div>
        <h2 style={{ fontSize: '20px', color: '#12b4c6' }}>L Indemnity</h2>
        <p>
          As far as the law allows, you agree to reimburse us, and our suppliers against any loss we or they may suffer from a claim
          brought against any one or more of us, where the claim results from:

        </p>
        <ul style={{ paddingLeft: '20px', listStyle: 'lower-alpha' }}>
          <li>your breach of your legal responsibilities under the agreement;</li>
          <li>your unauthorised use of the intellectual property;</li>
          <li>
            any deliberate or unlawful act that you commit or failure to act. The legal responsibilities set out in this section L will
            survive the end of the agreement

          </li>
        </ul>
        <p>
          You accept that this clause requires you to take on risk and legal responsibility for claims, loss and damages that we, our
          affiliates and our suppliers might suffer. We are not legally responsible to you or anyone else for any claims made against
          us, our affiliates or our suppliers. You accept that this clause may also lead to us, our affiliates or our suppliers, having
          claims against you and to you being legally responsible to us for additional amounts, including for any costs or damages
          we, our affiliates or suppliers are required to pay

        </p>
      </div>
      <div>
        <h2 style={{ fontSize: '20px', color: '#12b4c6' }}>M The right to sub-contract services and administration</h2>
        <p>We have the right to sub-contract to our partners or suppliers:</p>
        <ul style={{ paddingLeft: '20px', listStyle: 'lower-alpha' }}>
          <li>some or all of the services;</li>
          <li>
            any of the operational, technical and administrative activities we perform to carry out the agreement. Any agreement to
            sub-contract does not release us from our legal responsibility to you under the agreement

          </li>
        </ul>
      </div>
      <div>
        <h2 style={{ fontSize: '20px', color: '#12b4c6' }}>N Transfer of rights and legal responsibilities</h2>
        <p>
          We have the right to at any time transfer all or some of our rights in terms of the agreement to any third party without your
          permission. This transfer of rights is known as a cession. As far as the law allows, we have the right to transfer all or some
          of our legal responsibilities under the agreement to any third party without your permission. This transfer of legal
          responsibilities is known as a delegation. We do not have to inform you if we delegate the legal responsibilities to any of
          our affiliates or to any sub-contractors we appoint.

        </p>
      </div>
      <div>
        <h2 style={{ fontSize: '20px', color: '#12b4c6' }}>ONeither you nor we give up rights</h2>
        <p>
          If you do not enforce or exercise your rights in the agreement, this does not mean that you have given up these rights. You
          have the right to enforce the rights in the agreement at any time. If we do not enforce or exercise our rights you have
          against us in terms of the agreement, this does not mean that we have given up these rights. We have the right to enforce
          the rights in the agreement at any time.

        </p>
      </div>
      <div>
        <h2 style={{ fontSize: '20px', color: '#12b4c6' }}>P Each provision is separate</h2>
        <p>
          Each provision in the agreement is separate. Parts of a provision are also separate. If any provision or part of a provision
          is or becomes illegal, invalid or unenforceable for any reason, it must be treated as if it had not been included in these
          terms and conditions. This does not make the rest of the provisions illegal, invalid or unenforceable

        </p>
      </div>
      <div>
        <h2 style={{ fontSize: '20px', color: '#12b4c6' }}>Q The Laws of The Gambia applies</h2>
        <p>
          These terms and conditions are governed by and must be interpreted under the laws of the Republic of The Gambia. This
          applies even if one or both of the following apply:

        </p>
        <ul style={{ paddingLeft: '20px', listStyle: 'lower-alpha' }}>
          <li>You do not live in the Gambia;</li>
          <li>You agreed to these terms and conditions outside the Republic of The Gambia</li>
        </ul>
      </div>
      <div>
        <h2 style={{ fontSize: '20px', color: '#12b4c6' }}>R The Service you subscribe to</h2>
        <p>
          We offer vehicle tracking services that allows you to locate your vehicle at any time, Information on historical routes, over
          speeding alert also get updates When:

        </p>
        <ul style={{ paddingLeft: '20px', listStyle: 'lower-alpha' }}>
          <li>the engine if tuned on;</li>
          <li>When the vehicle leaved a packed area (Geo fencing);</li>
          <li>When the engine is turned on</li>
        </ul>
        <p>
          Be aware we do not offer vehicle recovery service. We can do our best to assist in recovery case there is vehicle Stolen or
          hijacked
        </p>
        <h3>1. If the vehicle is stolen or hijacked</h3>
        <p>You must do all of the following:</p>
        <ul style={{ paddingLeft: '20px', listStyle: 'lower-alpha' }}>
          <li>Phone us. We will do our best to offer all the needed support.</li>
          <li>
            Give us the information we ask for to make sure that your request is valid, legal or made by a person who is authorised
            to report the theft or hijacking to us.

          </li>
          <li>Give us the assistance that we ask for to assist in locating the vehicle.</li>
          <li>Immediately report the theft or hijacking to the police</li>
        </ul>
        <h3>2 After the vehicle is found</h3>
        <ul style={{ paddingLeft: '20px', listStyle: 'lower-alpha' }}>
          <li>We will tell you if we find the vehicle.</li>
          <li>
            It is your legal responsibility to arrange for the vehicle to be returned to you once the vehicle has been processed by the
            police, whether by making arrangements with the police or otherwise. You accept that it is not our legal responsibility to
            return the vehicle to you.

          </li>
        </ul>
        <h3>3 When we will not try to locate the vehicle</h3>
        <p>We are not required to try to locate the vehicle in any of these circumstances:</p>
        <ul style={{ paddingLeft: '20px', listStyle: 'lower-alpha' }}>
          <li>If you or the person authorised to report the theft or hijacking to us does not follow the correct activation procedure;</li>
          <li>If we are not reasonably satisfied with the responses to our questions when you ask us to locate the vehicle;</li>
          <li>If you willingly handed the vehicle to someone who did not return it;</li>
          <li>If the vehicle is stolen or hijacked outside The Gambia;</li>
          <li>While the vehicle is outside The Gambia;</li>
          <li>If the Services are suspended because you did not pay us;</li>
          <li> If the agreement has ended.</li>
        </ul>
        <p>
          we are not required to locate or recover the vehicle; you accept that we are not legally responsible for any loss or damages
          you suffer because we do not try to locate the vehicle.

        </p>
        <h3>4 The police and other law enforcement agencies act in an official capacity</h3>
        <p>
          in locating a stolen or hijacked vehicle, they are acting in their official capacity. Although we and the police or others may
          co-operate with each other to locate a stolen or hijacked vehicle, they are not our representatives or employees

        </p>
        <p>
          You accept that we are not legally responsible for: the acts or failures to act of the police or other law enforcement agencies,
          including any intentional or negligent acts or failures to act; any loss or damages that you suffer because of the acts or
          failures to act of the police or other LEA, including any intentional or negligent acts or failures to act

        </p>
        <h3>5 No guarantee that we will locate the stolen or hijacked vehicle</h3>
        <p>
          We will do our best to locate or recover the stolen or hijacked vehicle, but we do not guarantee that we will be able to
          locate or recover the vehicle. If we are unable to locate or recover the vehicle, the agreement will end in the month after
          the month in which the vehicle was stolen or hijacked. You accept that: • we do not guarantee that we can locate or recover
          the vehicle; • we are not legally responsible for any loss or damages you suffer if we are unable to locate the vehicle

        </p>
        <h3>Notices by SMS or email </h3>
        <p>We have the right to send you notices about the following issues by SMS or email:</p>
        <ul style={{ paddingLeft: '20px', listStyle: 'lower-alpha' }}>
          <li>Confirmation of your entering into this agreement;</li>
          <li>Increases in fees;</li>
          <li>Defective units or units that are not reporting;</li>
          <li>Not receiving your payments in time or at all;</li>
          <li>Notices that we intend to suspend the services to you</li>
        </ul>
      </div>

    </div>
  </PageLayout>
);

export default NotificationsPage;
